import React from 'react'
import MixpanelUtil from '../mixpanel'

const mp = MixpanelUtil.getInstance()
export const textBoxStyle = {
	border: '1px solid #ccc',
	padding: '10px',
	margin: '10px',
	borderRadius: '10px',
	backgroundColor: 'linear-gradient(to right, #f1f1f1, #ffffff)', // Gradient background
	boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow for 3D effect
	cursor: 'pointer', // Pointer cursor on hover
	transition: 'box-shadow 0.3s ease', // Smooth transition for hover effect
	'&:hover': {
		boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)', // Slightly larger shadow on hover
	},
	fontStyle: 'italic', // Italic text
	fontSize: '12px',
	color: '#555', // Softer text color
	overflow: 'hidden', // Hide text overflow
	positiion: 'relative',
}

function StringBox({ suggestion, onItemSelect }) {
	const handleClick = () => {
		mp.track('suggestion_selected', { suggestion: suggestion.content })
		if (onItemSelect) {
			onItemSelect(suggestion)
		}
	}

	return (
		<div style={textBoxStyle} onClick={handleClick} onKeyDown={handleClick}>
			{suggestion.content}
		</div>
	)
}

function Suggestions({ suggestions, onItemSelect }) {
	return (
		<>
			{suggestions.map((suggestion, index) => {
				return <StringBox key={index} suggestion={suggestion} onItemSelect={onItemSelect} />
			})}
		</>
	)
}

export default Suggestions
