import React from 'react'

const TypingIndicator = () => {
	const dotStyle = {
		display: 'inline-block',
		width: '10px',
		height: '10px',
		backgroundColor: '#333',
		borderRadius: '50%',
		margin: '0 5px',
		animationDuration: '1s',
		animationIterationCount: 'infinite',
		animationTimingFunction: 'ease-in-out',
	}

	const animations = {
		first: {
			animationName: 'bounce',
		},
		second: {
			animationName: 'bounce',
			animationDelay: '0.2s',
		},
		third: {
			animationName: 'bounce',
			animationDelay: '0.4s',
		},
	}

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				margin: '12px',
			}}
		>
			<span style={{ ...dotStyle, ...animations.first }} />
			<span style={{ ...dotStyle, ...animations.second }} />
			<span style={{ ...dotStyle, ...animations.third }} />
			<style>
				{`
                // @keyframes shimmer {
                //     0% {
                //         background-position: 140%;
                //     }
                //     100% {
                //         background-position: -40%;
                //     }
                // }
                @keyframes bounce {
                    0%, 20%, 50%, 80%, 100% {
                    transform: translateY(0);
                    }
                    40% {
                    transform: translateY(-10px);
                    }
                    60% {
                    transform: translateY(-5px);
                    }
                }
            `}
			</style>
		</div>
	)
}

export default TypingIndicator
