import React, { CSSProperties, useEffect, useRef, forwardRef, useState } from 'react'
import { Message } from '../types/Message'
import MixpanelUtil from '../mixpanel'
import ChatBubble from './ChatBubble'
import Step from './Step'
import TypingIndicator from './TypingIndicator'
import ThumbsComponent from './ThumbsFeedback'

const mp = MixpanelUtil.getInstance()

type Props = {
	isAtBottom: boolean
	setIsAtBottom: (isAtBottom: boolean) => void
	messages: Message[]
	responseGenerationState: string | boolean
	isThumbsShowing: boolean
	conversationId: string
	latestUserMessageId: string
}

const ChatDisplay = forwardRef<HTMLDivElement, Props>(
	(
		{
			isAtBottom,
			setIsAtBottom,
			messages,
			responseGenerationState,
			isThumbsShowing,
			conversationId,
			latestUserMessageId,
		},
		ref,
	) => {
		const messagesEndRef = useRef<HTMLDivElement>(null)

		// Observe whether the messageEndRef is on screen, which indicates that we are scrolled to the bottom.
		useEffect(() => {
			const observer = new IntersectionObserver(
				(entries) => {
					const entry = entries[0]
					setIsAtBottom(entry.isIntersecting)
				},
				{
					threshold: 1.0, // Trigger when the entire element is in view
				},
			)

			if (messagesEndRef.current) {
				observer.observe(messagesEndRef.current)
			}

			// Clean up
			return () => {
				if (messagesEndRef.current) {
					observer.unobserve(messagesEndRef.current)
				}
			}
		}, [setIsAtBottom])

		useEffect(() => {
			if (messages?.length === 1) {
				mp.track('Initial conversation message displayed')
			}
		}, [messages])

		const containerStyle: CSSProperties = {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			overflowY: 'auto',
		}

		return (
			<div ref={ref} className='chat-list' style={containerStyle}>
				{messages.map((msg, index) => {
					if (msg.role === 'step') {
						return <Step step={msg} />
					} else {
						return (
							msg.content && (
								<div
									key={index}
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<ChatBubble role={msg.role} content={msg.content} />
								</div>
							)
						)
					}
				})}
				{responseGenerationState === 'Step' && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							fontWeight: 'bold',
							fontSize: '2em',
						}}
					>
						Analyzing
					</div>
				)}
				{responseGenerationState && <TypingIndicator />}
				{isThumbsShowing && (
					<ThumbsComponent
						conversationId={conversationId}
						latestUserMessageId={latestUserMessageId}
					/>
				)}
				<div ref={messagesEndRef} />
			</div>
		)
	},
)

ChatDisplay.displayName = 'ChatDisplay'
export default ChatDisplay
