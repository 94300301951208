import React from 'react'
import Modal from 'react-modal'

import MixpanelUtil from '../mixpanel'

const mp = MixpanelUtil.getInstance()
// Modal.setAppElement('#root') // This line is needed for accessibility reasons

/*
Error states:
- Timeout
- Unauthorized
- Not available
- No internet
- Update required

- Server error
- Bad request

*/

interface ErrorModalProps {
	isOpen: boolean
	errorMessage: string
	onClose: () => void
}

const ErrorModal: React.FC<ErrorModalProps> = ({ isOpen, errorMessage, onClose }) => {
	return (
		<Modal
			isOpen={isOpen}
			onAfterOpen={() => mp.track("Error Modal Opened", {errorMessage})}
			onAfterClose={() => mp.track("Error Modal Closed")}
			onRequestClose={onClose}
			contentLabel='Error Dialog'
			style={{
				content: {
					top: '50%',
					left: '50%',
					right: 'auto',
					bottom: 'auto',
					marginRight: '-50%',
					transform: 'translate(-50%, -50%)',
					width: 'auto',
					height: 'auto',
					border: '1px solid #ccc',
					background: '#fff',
					overflow: 'auto',
					WebkitOverflowScrolling: 'touch',
					borderRadius: '4px',
					outline: 'none',
					padding: '20px',
					textAlign: 'center',
				},
			}}
		>
			<h2>Error</h2>
			<p>{errorMessage}</p>
			<button type='button' onClick={onClose}>
				Close Dialog
			</button>
		</Modal>
	)
}

export default ErrorModal
