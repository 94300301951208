import React, { CSSProperties, useState } from 'react'
import MixpanelUtil from '../mixpanel'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism'

const mp = MixpanelUtil.getInstance()

function Step({ step }) {
	const outerStyle: CSSProperties = {
		borderRadius: '20px',
		maxWidth: '100%',
		fontSize: '14px',
		overflowWrap: 'break-word',
		color: 'black',
		margin: '10px',
		alignSelf: 'flex-start',
	}

	const [isExpanded, setIsExpanded] = useState(false)

	const onClickSteps = () => {
		setIsExpanded(!isExpanded)
		mp.track('steps_expanded', { isExpanded: !isExpanded })
	}

	if (!shouldShowStep(step)) {
		return <div />
	}

	return (
		<div style={outerStyle}>
			<button type='button' onClick={onClickSteps}>
				{isExpanded ? 'Hide steps' : 'See steps'}
			</button>
			{isExpanded && <div>{InnerStep({ step })}</div>}
		</div>
	)
}

function shouldShowStep(step) {
	if (!(step.tool_calls?.length > 0)) {
		return false
	}

	for (const toolCall of step.tool_calls) {
		if (toolCall.code_interpreter?.input || toolCall.code_interpreter?.outputs[0]?.logs) {
			return true
		}
	}

	return false
}

function InnerStep({ step }) {
	const stepStyle: CSSProperties = {
		padding: '10px 15px',
		borderRadius: '20px',
		maxWidth: '100%',
		fontSize: '14px',
		overflowWrap: 'break-word',
		color: 'black',
		alignSelf: 'flex-start',
	}

	const style = stepStyle
	const toolCalls = step.tool_calls

	return (
		<div style={style}>
			{toolCalls.map((toolCall) => {
				return (
					<div key={toolCall.id}>
						{toolCall.code_interpreter?.input && (
							<div style={{ backgroundColor: 'lightgray' }}>
								<SyntaxHighlighter language='javascript' style={solarizedlight}>
									{toolCall.code_interpreter.input}
								</SyntaxHighlighter>
							</div>
						)}
						{toolCall.code_interpreter?.outputs[0]?.logs && (
							<div style={{ backgroundColor: 'black', color: 'white' }}>
								<SyntaxHighlighter language='javascript' style={solarizedlight}>
									{toolCall.code_interpreter.outputs[0].logs}
								</SyntaxHighlighter>
							</div>
						)}
					</div>
				)
			})}
		</div>
	)
}

export default Step
