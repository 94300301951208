import React, { type CSSProperties } from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism'
import copy from 'copy-to-clipboard'
import MixpanelUtil from '../mixpanel'
import { FaRegCopy } from 'react-icons/fa6'

const mp = MixpanelUtil.getInstance()
function Code({ language, codeBlock }) {
	let str = 'language-javascript'
	str = str.replace(/^language-/, '')
	const lang = str === 'excel' ? 'excelFormula' : str
	const handleCopy = () => {
		mp.track('Code copy', { lang })
		copy(codeBlock)
	}
	return (
		<div
			style={{
				position: 'relative',
				cursor: 'pointer',
			}}
			onClick={handleCopy}
			onKeyUp={handleCopy}
		>
			<FaRegCopy
				style={{
					position: 'absolute',
					top: 10,
					right: 10,
					background: 'transparent',
					border: 'none',
				}}
				size={15}
			/>
			<SyntaxHighlighter language={lang} style={solarizedlight}>
				{codeBlock}
			</SyntaxHighlighter>
		</div>
	)
}

const baseStyle: CSSProperties = {
	padding: '10px 15px',
	borderRadius: '20px',
	fontSize: '14px',
	overflowWrap: 'break-word',
	maxWidth: '80%',
}

export const userStyle: CSSProperties = {
	...baseStyle,
	backgroundColor: '#A6DAD1', // Green color
	color: 'black',
	alignSelf: 'flex-end', // Align to the right for 'mine' bubbles
	borderBottomRightRadius: '5px', // Adjust border radius for a chat-like appearance
	margin: '5px 5px 5px 5px', // Adjust margin as needed
}

export const systemStyle: CSSProperties = {
	...baseStyle,
	backgroundColor: '#e0e0e0',
	color: 'black',
	alignSelf: 'flex-start',
	borderBottomLeftRadius: '5px', // Adjust border radius for a chat-like appearance
	margin: '5px 5px 5px 5px', // Adjust margin as needed
}

function ChatBubble({ role, content }: { role: string; content: string }) {
	const style = role === 'user' ? userStyle : systemStyle
	return (
		<div style={style}>
			<ReactMarkdown
				components={{
					ul: ({ node, ...props }) => {
						const depth = node?.position?.start?.column || 0 - 1
						return <ul style={{ paddingLeft: `${20 + depth * 5}px` }} {...props} />
					},
					ol: ({ node, ...props }) => {
						const depth = node?.position?.start.column || 0 - 1
						return <ol style={{ paddingLeft: `${20 + depth * 5}px` }} {...props} />
					},
					code: ({ node, ...props }) => {
						if (props.className) {
							return Code({ language: props.className, codeBlock: props.children })
						} else {
							return <strong>{props.children}</strong>
						}
					},
				}}
			>
				{content}
			</ReactMarkdown>
		</div>
	)
}

export default ChatBubble
