import _ from 'lodash'
import MixpanelUtil from './mixpanel'
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source'

const mp = MixpanelUtil.getInstance()

export type FetchResult = {
	response: Response
	errorState?: ErrorState
	errorString?: string
	body?: any
}
export interface PreviousConversationSummary {
	conversation_id: string
	summary: string
	seconds_ago: number
}

export function instanceOfPreviousConversationSummary(
	object: any,
): object is PreviousConversationSummary {
	return 'conversation_id' in object && 'summary' in object && 'seconds_ago' in object
}

class Api {
	private static instance: Api
	private _baseUrl: string = process.env.REACT_APP_API_URL || ''

	private _token = ''

	private constructor() {}

	// Using a singleton here for the baseurl is kind of a backchannel way to handle global state. But it's just for debugging.
	static getInstance(): Api {
		if (!Api.instance) {
			Api.instance = new Api()
		}
		return Api.instance
	}

	get baseUrl(): string {
		return this._baseUrl
	}

	set baseUrl(url: string) {
		this._baseUrl = url
	}

	// TODO refer to react state for token
	get token(): string {
		return this._token
	}
	set token(value: string) {
		this._token = value
	}

	async callGetAccount() {
		const url = this._baseUrl + '/me'
		const options = { method: 'GET' }
		const result = await fetchWithHeaders(url, options)
		if (result.response.ok) {
			const json = await result.response.json()
			result.body = json
		}
		return result
	}

	async callGetUsage() {
		const url = this._baseUrl + '/me/usage'
		const options = { method: 'GET' }
		const result = await fetchWithHeaders(url, options)
		if (result.response.ok) {
			const json = await result.response.json()
			result.body = json
		}
		return result
	}

	async callGetUserData(token): Promise<FetchResult> {
		const url = this._baseUrl + '/login/microsoft_sso'
		const options = {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + token,
			},
		}

		const result = await fetchWithHeaders(url, options)
		if (result.response.ok) {
			const json = await result.response.json()
			result.body = json
		}

		return result
	}

	async callLogin(userData): Promise<FetchResult> {
		const url = this._baseUrl + '/login'
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(userData),
		}

		const result = await fetchWithHeaders(url, options)
		if (result.response.ok) {
			result.body = await result.response.json()
		}

		return result
	}

	async callLoginFallback(): Promise<FetchResult> {
		const url = this._baseUrl + '/login/fallback'
		const options = {
			method: 'GET',
		}

		const result = await fetchWithHeaders(url, options)
		if (result.response.ok) {
			result.body = await result.response.json()
		}

		return result
	}

	async download(spreadsheetId, spreadsheetData): Promise<FetchResult> {
		const apiUrl = `${this._baseUrl}/spreadsheets/${spreadsheetId}/download`
		const body = {
			data: spreadsheetData,
			platform: 'excel',
		}
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}
		const result = await fetchWithHeaders(apiUrl, options)
		return result
	}

	async fetchVersion(spreadsheetId): Promise<FetchResult> {
		const apiUrl = `${this._baseUrl}/spreadsheets/${spreadsheetId}/latest_version`
		const result = await fetchWithHeaders(apiUrl, { method: 'GET' })
		if (result.response.ok) {
			result.body = await result.response.json()
		}
		return result
	}

	async createVersion(spreadsheetId: string, file: File): Promise<FetchResult> {
		const apiUrl = `${this._baseUrl}/spreadsheets/${spreadsheetId}/version`
		const formData = new FormData()
		formData.append('file', file)
		const options = {
			method: 'POST',
			body: formData,
		}
		return await fetchWithHeaders(apiUrl, options)
	}

	// Get Suggestions
	async fetchSuggestions(conversationId: string, spreadsheetId): Promise<FetchResult | string[]> {
		if (!conversationId) {
			conversationId = 'default'
		}
		const apiUrl =
			this._baseUrl +
			'/conversations/' +
			conversationId +
			'/suggestions?spreadsheet_id=' +
			spreadsheetId
		const options = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const result = await fetchWithHeaders(apiUrl, options)
		const response = result.response
		if (!response.ok) {
			return result
		}

		const responseData = await response.json()
		const suggestions = responseData

		return suggestions
	}

	// Create conversation
	async createConversation(spreadsheet_id: string): Promise<FetchResult> {
		console.log(this._baseUrl)
		const apiUrl = `${this._baseUrl}/conversations`
		const body = JSON.stringify({ spreadsheet_id })
		const result = await fetchWithHeaders(apiUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body,
		})
		if (result.response.ok) {
			const body = await result.response.json()
			result.body = body.conversationId
		}
		return result
	}

	async fetchConversation(conversationId): Promise<FetchResult> {
		const apiUrl = `${this._baseUrl}/conversations/${conversationId}`
		const result = await fetchWithHeaders(apiUrl, { method: 'GET' })

		if (result.response.ok) {
			result.body = await result.response.json()
		}
		return result
	}

	async fetchLatestConversationSummary(
		spreadsheetId: string,
	): Promise<PreviousConversationSummary | FetchResult> {
		const apiUrl = `${this._baseUrl}/spreadsheets/${spreadsheetId}/latest_conversation_summary`
		const result = await fetchWithHeaders(apiUrl, { method: 'GET' })
		if (result.response.ok) {
			result.body = await result.response.json()
			return {
				conversation_id: result.body.conversation_id,
				summary: result.body.summary,
				seconds_ago: result.body.seconds_ago,
			}
		}
		return result
	}

	// Post conversation
	async postConversation(
		conversationId: string,
		spreadsheetData,
		message,
		queryType,
		listener,
		abortController,
	): Promise<void> {
		console.log('Posting conversation')
		console.log(this._baseUrl)
		const url = this._baseUrl + '/conversations/' + conversationId + '/messages'
		const body = {
			message: message,
			spreadsheet_metadata: spreadsheetData,
			type: queryType,
		}

		const token = Api.getInstance().token
		console.log(token)
		const headers = {
			'Content-Type': 'application/json',
			'Ngrok-Skip-Browser-Warning': 'true',
			Authorization: `Bearer ${token}`,
		}
		return fetchEventSource(url, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(body),
			signal: abortController.signal,
			openWhenHidden: true,
			async onopen(response) {
				if (
					response.ok &&
					response.headers.get('content-type') === EventStreamContentType
				) {
					return // everything's good
				} else {
					const eventError = await getErrorResponse(response)
					listener({ event: 'error', data: eventError })
					throw eventError // Abort the stream
				}
			},
			onmessage(msg) {
				if (msg.event === 'FatalError') {
					listener({ event: 'error', data: msg })
					throw msg
				} else {
					listener(msg)
				}
			},
			onclose() {
				listener({ event: 'end', data: '' })
			},
			onerror(err) {
				listener({ event: 'error', data: err })
				throw err
			},
		})
	}

	async postThumb(conversationId: string, vote: string, userMessageId: string) {
		const url = this._baseUrl + '/conversations/' + conversationId + '/thumbs'
		const body = {
			vote: vote,
			user_message_id: userMessageId,
		}
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		const result = await fetchWithHeaders(url, options)
		return result
	}
}

async function fetchWithHeaders(url: string, options: RequestInit = {}): Promise<FetchResult> {
	const startTime = new Date()
	// Skip ngrok warning
	const headers = new Headers(options.headers)
	if (url.includes('ngrok')) {
		headers.append('ngrok-skip-browser-warning', 'true')
	}

	if (!headers.has('Authorization')) {
		const token = Api.getInstance().token
		headers.append('Authorization', `Bearer ${token}`)
	}

	const mergedOptions: RequestInit = {
		...options,
		headers,
	}

	try {
		const response = await fetch(url, mergedOptions)

		if (!response.ok) {
			return await getErrorResponse(response)
		}

		return { response }

		// Fetch throws an error if the endpoint is unavailable or it aborts (usually due to a timeout)
	} catch (error) {
		const endTime = new Date()
		const duration = endTime.getTime() - startTime.getTime()

		const errorResponse = new Response(null, {
			status: 500,
			statusText: error,
		})

		// If the operation took more than 30s, assume a timeout
		if (duration > 30 * 1000) {
			return {
				response: errorResponse,
				errorState: ErrorState.Timeout,
				errorString: getErrorString(ErrorState.Timeout),
			}
		} else {
			return {
				response: errorResponse,
				errorState: ErrorState.NotAvailable,
				errorString: getErrorString(ErrorState.NotAvailable),
			}
		}
	}
}

async function getErrorResponse(response: Response): Promise<FetchResult> {
	if (response.ok) {
		return { response }
	}

	// Fastapi likes to put error messages in the body of the response, so we need to check for that.
	const clonedResponse = response.clone()
	const json = await response.json()

	const errorKeys = ['detail', 'message', 'error', 'description', 'details']
	const errorKey = _.find(errorKeys, (key) => _.get(json, key)) || 'Unknown error'
	const errorMessage = _.get(json, errorKey)
	if (json && !response.statusText) {
		response = new Response(clonedResponse.body, {
			status: clonedResponse.status,
			statusText: errorMessage,
			headers: clonedResponse.headers,
		})
	}

	const errorState = getErrorState(response)
	const errorString = getErrorString(errorState)

	const result = {
		response: json,
		errorState: errorState,
		errorString: errorString,
		errorDetail: errorMessage,
	}

	if (result.errorDetail !== 'Index not found' && !result.errorDetail.includes('AAD')) {
		mp.track('Api Error', result)
	}
	return result
}

export enum ErrorState {
	Timeout,
	Unauthorized,
	NotAvailable,
	NoInternet,
	UpdateRequired,
	NotFound,

	ServerError,
	BadRequest,
	Other,
}

function getErrorState(response: Response): ErrorState {
	if (response.status === 401 || response.status === 403) {
		return ErrorState.Unauthorized
	}
	if (response.status === 400) {
		return ErrorState.BadRequest
	}
	if (response.status === 404) {
		return ErrorState.NotFound
	}
	if (response.status === 500) {
		return ErrorState.ServerError
	}
	if (response.status >= 502 && response.status <= 504) {
		return ErrorState.NotAvailable
	}

	return ErrorState.Other
}

function getErrorString(state: ErrorState): string {
	switch (state) {
		case ErrorState.Timeout:
			return 'The request timed out. Please try again.'

		case ErrorState.Unauthorized:
			return 'You are not authorized to perform this action. Try closing and reopening Rowsie to log in again.'

		case ErrorState.NotAvailable:
			return 'Rowsie not available. Are you connected to the internet?'

		case ErrorState.NoInternet:
			return 'Rowsie not available. Are you connected to the internet?'

		case ErrorState.UpdateRequired:
			return 'Rowsie needs to be updated. Please restart Excel to load the latest version.'

		case ErrorState.NotFound:
			return 'Resource not found.'

		case ErrorState.ServerError:
			return 'Oops, we have encountered an error. We have been notified. Please contact support if you have additional context on what might be wrong.'

		case ErrorState.BadRequest:
			return 'Oops, we have encountered an error. We have been notified. Please contact support if you have additional context on what might be wrong.'

		default:
			return 'Oops, we have encountered an error. We have been notified. Please contact support if you have additional context on what might be wrong.'
	}
}

export default Api
