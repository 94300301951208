import React from 'react'
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa'
import Api from '../api'
import MixpanelUtil from '../mixpanel'

const mp = MixpanelUtil.getInstance()
const api = Api.getInstance()

const button: React.CSSProperties = {
	backgroundColor: '#125d56',
	color: 'white',
	marginTop: '10px',
	marginRight: '10px',
	borderRadius: '5px',
	fontSize: '14px',
	border: 'none',
	cursor: 'pointer',
	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
	padding: '10px 20px',
}

const ThumbsComponent = ({ conversationId, latestUserMessageId }) => {
	const [isComplete, setIsComplete] = React.useState(false)
	const [isFoundersShowing, setIsFoundersShowing] = React.useState(false)

	const onVote = async (voteType: string) => {
		mp.track('thumbs_vote', { vote: voteType })
		if (Math.random() < 0.25) {
			mp.track('founders_shown')
			setIsFoundersShowing(true)
		}
		setIsComplete(true)
		await api.postThumb(conversationId, voteType, latestUserMessageId)
	}
	const onFoundersClick = () => {
		mp.track('founders_clicked')
		window.open(foundersLink, '_blank', 'noopener,noreferrer')
	}

	const foundersLink = 'https://calendly.com/greg-rowsie'
	const foundersString =
		'We would love to hear more about your experience with Rowsie. Would you be open to meeting with one of the founders? '

	return isComplete ? (
		isFoundersShowing ? (
			<div style={{ margin: '2px 10px 2px 2px' }}>
				{foundersString}
				<button type='button' style={button} onClick={onFoundersClick}>
					Sign up to help us shape the future of Rowsie!
				</button>
			</div>
		) : (
			<div style={{ margin: '2px 10px 2px 2px' }}>Thank you for your feedback!</div>
		)
	) : (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
			<p style={{ margin: '2px' }}>Did you find this helpful?</p>
			<div
				style={{
					display: 'flex',
					justifyContent: 'left',
					width: '100px',
					margin: '2px 10px 2px 2px',
				}}
			>
				<button type='button' onClick={() => onVote('up')}>
					<FaThumbsUp />
				</button>
				<button type='button' onClick={() => onVote('down')}>
					<FaThumbsDown />
				</button>
			</div>
		</div>
	)
}

export default ThumbsComponent
