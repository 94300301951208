import React from 'react'
import { BsArrowRightSquareFill } from 'react-icons/bs'
import { FaRegStopCircle } from 'react-icons/fa'

type ChatInputProps = {
	inputText: string
	isRequestInFlight: boolean
	onUpdateMessage: (message: string) => void
	onSendMessage: () => void
	onCancel: () => void
}

const ChatInput = ({
	inputText,
	isRequestInFlight,
	onUpdateMessage,
	onSendMessage,
	onCancel,
}: ChatInputProps) => {
	const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		onUpdateMessage(event.target.value)
	}

	const handleSendClick = () => {
		if (onSendMessage) {
			onSendMessage()
		}
		onUpdateMessage('') // Clear the textarea after sending the message
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault() // Prevent adding a new line when pressing Enter
			handleSendClick()
		}
	}
	return (
		<div
			id='input-area'
			style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
		>
			<textarea
				id='user-input'
				rows={3}
				value={inputText}
				onChange={handleInputChange}
				onKeyDown={handleKeyDown}
				style={{
					flex: 1,
					margin: '10px',
					borderRadius: '5px',
					fontSize: '14px',
					paddingRight: '40px', // Add padding to prevent text from overlapping the button
				}}
			/>
			{isRequestInFlight ? (
				<button
					id='cancel-button'
					type='button'
					onClick={onCancel}
					style={{
						backgroundColor: 'transparent',
						position: 'absolute', // Position the button absolutely
						right: '10px', // Position the button to the right
						height: '80%', // Make the button fill the height of the container
						border: 'none', // Remove the border
						padding: '0 10px', // Add some padding
					}}
				>
					<FaRegStopCircle size={20} color='#125d56' />
				</button>
			) : (
				<button
					id='send-button'
					type='button'
					onClick={handleSendClick}
					style={{
						backgroundColor: 'transparent',
						position: 'absolute', // Position the button absolutely
						right: '10px', // Position the button to the right
						height: '80%', // Make the button fill the height of the container
						border: 'none', // Remove the border
						padding: '0 10px', // Add some padding
					}}
				>
					<BsArrowRightSquareFill size={30} color='#125d56' />
				</button>
			)}
		</div>
	)
}

export default ChatInput
